var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: { maskClosable: false, title: "新增" },
      on: { cancel: _vm.cancel, ok: _vm.handleOk },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "a-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                name: "avatar",
                "list-type": "picture-card",
                "show-upload-list": false,
                "before-upload": _vm.beforeUpload,
              },
              on: { change: _vm.handleUpChange },
            },
            [
              _vm.imageUrl
                ? _c("img", {
                    ref: "showImg",
                    staticStyle: {
                      "max-width": "200px",
                      "max-height": "200px",
                    },
                    attrs: { src: _vm.imageUrl, alt: "avatar" },
                  })
                : _c(
                    "div",
                    [
                      _c("a-icon", { attrs: { type: "plus" } }),
                      _c("div", { staticClass: "ant-upload-text" }, [
                        _vm._v("\n          Upload\n        "),
                      ]),
                    ],
                    1
                  ),
            ]
          ),
          _c("a-input", {
            attrs: { "allow-clear": "", placeholder: "请粘贴图片或链接" },
            on: {
              change: () => {
                this.textMsg = ""
              },
            },
            nativeOn: {
              "!paste": function ($event) {
                $event.preventDefault()
                return _vm.pasting.apply(null, arguments)
              },
            },
            model: {
              value: _vm.textMsg,
              callback: function ($$v) {
                _vm.textMsg = $$v
              },
              expression: "textMsg",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <a-modal :maskClosable="false" v-model="open" title="新增" @cancel="cancel" @ok="handleOk">
    <div>
      <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload" @change="handleUpChange">
        <img ref='showImg' style="max-width:200px;max-height:200px" v-if="imageUrl" :src="imageUrl" alt="avatar" />
        <div v-else>
          <a-icon :type=" 'plus'" />
          <div class="ant-upload-text">
            Upload
          </div>
        </div>
      </a-upload>
      <a-input allow-clear v-model="textMsg" @change="()=>{this.textMsg=''}" placeholder="请粘贴图片或链接" @paste.native.capture.prevent="pasting" />
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      open: false,
      textMsg: '',
      canUpload: false,
      imageUrl: '',
      fileList: [],
    }
  },
  computed: {},
  created() {},
  methods: {
    handleCancel() {
      this.previewVisible = false
    },
    openModal() {
      this.open = true
    },
    handlePreview(file) {
      const url = window.URL ? window.URL.createObjectURL(file) : window.webkitURL.createObjectURL(file)
      this.previewImage = url
      this.previewVisible = true
    },
    handleOk() {},
    cancel() {},
    beforeUpload(file) {
      this.fileList = [...this.fileList, file]
      const url = window.URL ? window.URL.createObjectURL(file) : window.webkitURL.createObjectURL(file)
      this.imageUrl = url
      this.textMsg = ''
      return false
    },
    pasting(event) {
      let txt = event.clipboardData.getData('Text')
      console.log(event.clipboardData.getData('Text'))
      if (typeof txt == 'string') {
        this.textMsg += txt
        this.imageUrl = txt
      }
      let file = null
      const items = (event.clipboardData || window.clipboardData).items
      if (items.length) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            file = items[i].getAsFile()
            this.handleChange(file)
            if (!this.canUpload) {
              this.canUpload = !this.canUpload
            }
            break
          }
        }
      }
    },
    handleUpChange({ file, fileList }) {
      return false
    },
    handleChange(file, filelist) {
      const url = window.URL ? window.URL.createObjectURL(file) : window.webkitURL.createObjectURL(file)
      this.imageUrl = url
      this.textMsg = ''
      return false
      /*       let formData = new FormData()
      formData.append('file', file.raw || file) */
      /*       axios
        .post(url, formData, {
          headers: {
            'Content-type': 'multipart/form-data',
            bwToken: token,
          },
        })
        .then((res) => {
          console.log(res.data, '1321312321')
          let obj = new Object()
          obj.url = res.data.data
          obj.name = res.data.data.split('/').slice(-1).join()
          this.fileList.push(obj)
          console.log(this.fileList)
        })
        .catch((err) => {
          console.log(err)
        }) */
    },
  },
}
</script>

<style lang="less" scoped>
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
